import React, { useEffect, useState } from 'react'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
const $ = require("jquery")
$.Datatable = require("datatables.net");

export default function PendingPayment() {
  const [data, setData] = useState([]);
  const [loadingIds, setLoadingIds] = useState({}); // track loading state per ID
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  const baseUrl = nodeMode === "development" ? beckendLocalApiUrl : beckendLiveApiUrl;
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const [hidePhone, setHidePhone] = useState(false);
  const [userType, setUserType] = useState(null);
  const [userIDPermission, setUserIDPermission] = useState(null);

  const setpageLimit = (event) => {
    setLimit(event.target.value);
  };

  const handlePageClick = (data) => {
    setPageNumber(data.selected + 1);
  };

  const All = () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios.get(`${baseUrl}User/manualpaymentlist?page=${pageNumber}&_limit=${limit}`, { headers })
      .then((res) => {
        setData(res.data.admin);
        setNumberOfPages(res.data.totalPages);
      });
  };

  useEffect(() => {
    All();
  }, [pageNumber, limit]);

  const updateStatus = (Id) => {
    if (loadingIds[Id]) return; // prevent multiple clicks

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    };

    setLoadingIds(prev => ({ ...prev, [Id]: 'approve' })); // set loading state for this ID

    axios.post(`${baseUrl}User/manualpayment/approve/${Id}`, {}, { headers })
      .then((res) => {
        alert(res.data.message);
        All();
      })
      .catch((error) => {
        console.error('Error updating status:', error);
        alert('Error updating status');
      })
      .finally(() => {
        setLoadingIds(prev => ({ ...prev, [Id]: false })); // reset loading state
      });
  };

  const rejectStatus = (Id) => {
    if (loadingIds[Id]) return; // prevent multiple clicks

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    };

    setLoadingIds(prev => ({ ...prev, [Id]: 'reject' })); // set loading state for this ID

    axios.post(`${baseUrl}User/manualpayment/reject/${Id}`, {}, { headers })
      .then((res) => {
        alert(res.data.message);
        All();
      })
      .catch((error) => {
        console.error('Error updating status:', error);
        alert('Error updating status');
      })
      .finally(() => {
        setLoadingIds(prev => ({ ...prev, [Id]: false })); // reset loading state
      });
  };

  const [editingIndex, setEditingIndex] = useState(null);
  const [amount, setAmount] = useState('');

  const handleEditClick = (index, currentAmount) => {
    setEditingIndex(index);
    setAmount(currentAmount);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSaveClick = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'application/json',
    };
  
    try {
      const response = await axios.put(`${baseUrl}User/updateAmount`, {
        
        id,
        amount: parseFloat(amount),
      }, { headers });
  
      console.log('Amount updated successfully:', response.data);
      All()
      setEditingIndex(null);
    } catch (error) {
      console.error('Error updating amount:', error);
    }
  };


  const dateFormat = (e) => {
    const date = new Date(e);
    return date.toLocaleString('default', { month: 'long', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' });
  }


  useEffect(() => {
    const agentme = async () => {
      const access_token = localStorage.getItem('token');
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };
      try {
        const response = await axios.get(`${baseUrl}me`, { headers });
        const { user_type, Permissions } = response.data;
        setUserType(user_type);
        setUserIDPermission(response.data._id);
        if (user_type === 'Agent' && Permissions[31].Status === false) {
          setHidePhone(true);
        }
      } catch (e) {
        alert(e);
      }
    };

    agentme();

  }, []);




  return (
    <>
      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Pending Payment</h4>
              <select className='form-control col-sm-2 m-1' id='pagelimit' name='pagelimit' onChange={setpageLimit}>
                <option value="10">Set limit</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead className="table-primary">
                    <tr>
                      <th>#</th>
                      <th>ID</th>
                      <th>Amount</th>
                      <th>Phone</th>
                      <th>UTR Number</th>
                      {/* <th>Screenshot</th> */}
                      <th>UPI ID</th>
                      <th>Status</th>
                      <th>Approve or Reject</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item._id}</td>
                        <td>
                              {editingIndex === index ? (
                                <>
                                  <input
                                    type="number"
                                    value={amount}
                                    onChange={handleAmountChange}
                                  />
                                  <button className="btn btn-primary ml-1" onClick={() => handleSaveClick(item._id)}>Save</button>
                                </>
                              ) : (
                                <>
                                  {item.amount}
                                  <button className="btn btn-link ml-1" onClick={() => handleEditClick(index, item.amount)}>Edit</button>
                                </>
                              )}
                        </td>
                        <td> {!hidePhone && item.Phone}</td>
                        
                        <td>{item.urtID || 'N/A'}</td>
                        {/* <td>{item.qrCodeURL ? (
                          <a href={`http://localhost:6002${item.qrCodeURL}`} target="_blank" rel="noopener noreferrer">
                            <img className="img" src={`http://localhost:6002${item.qrCodeURL}`} alt="SS" style={{
                              borderRadius: '5px',
                              width: '4rem',
                              height: '4rem',
                            }} />
                          </a>) : 'N/A'}
                        </td> */}
                        <td>{item.upiId}</td>
                        <td>{item.status}</td>
                        <td>
                          <button
                            className="btn btn-success mr-1"
                            onClick={() => updateStatus(item._id)}
                            disabled={loadingIds[item._id] === 'approve'}
                          >
                            {loadingIds[item._id] === 'approve' ? 'Approving...' : 'Approve'}
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => rejectStatus(item._id)}
                            disabled={loadingIds[item._id] === 'reject'}
                          >
                            {loadingIds[item._id] === 'reject' ? 'Rejecting...' : 'Reject'}
                          </button>
                        </td>
                        {/* <td>{new Date(item.createdAt).toLocaleDateString()}</td> */}
                        <td>{dateFormat(item.createdAt).split(',')[0]}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='mt-4'>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  pageCount={numberOfPages}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination justify-content-center"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
