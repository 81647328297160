import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Gateway from "./Gateway";
import ReactSwitch from "react-switch";

export const Sitesettings = () => {

  const [WebTitle, setWebTitle] = useState("");
  const [WebsiteName, setWebName] = useState("");
  const [msg, setMsg] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [CompanyAddress, setCompanyAddress] = useState("");
  const [CompanyMobile, setCompanyMobile] = useState("");
  const [CompanyEmail, setCompanyEmail] = useState("");
  const [CompanyWebsite, setCompanyWebsite] = useState("");
  const [RoomCode, setRoomCode] = useState("");
  const [RoomCodeUrl, setRoomCodeUrl] = useState("");
  const [Logo, setLogo] = useState("");
  const [SmallLogo, setSmallLogo] = useState("");

  const [LandingImage1, setLandingImage1] = useState("");
  const [LandingImage2, setLandingImage2] = useState("");
  const [LandingImage3, setLandingImage3] = useState("");
  const [LandingImage4, setLandingImage4] = useState("");

  const [isLandingImage1, issetLandingImage1] = useState(true);
  const [isLandingImage2, issetLandingImage2] = useState(true);
  const [isLandingImage3, issetLandingImage3] = useState(true);
  const [isLandingImage4, issetLandingImage4] = useState(true);
  const [isBonus, setIsBonus] = useState(true);
  const [bonusSignup, setBonusSignup] = useState(0);
  const [refer, setrefer] = useState(0);
  const [commission, setcommission] = useState(0);
  const [checkedRoom, setCheckedRoom] = useState(true);
  const [isWebsiteOn, setIsWebsiteOn] = useState(true);
  const [isUpiOn, setIsUpiOn] = useState(true);
  const [isUpiLiveOn, setIsUpiLiveOn] = useState(true);
  const [isAccountOn, setIsAccountOn] = useState(true);
  const [isBatOn, setIsBatOn] = useState(true);
  const [minWithDrawal, setminWithDrawal] = useState(0);
  const [maxWithDrawal, setmaxWithDrawal] = useState(0);
  const [minDeposit, setminDeposit] = useState(0);
  const [maxDeposit, setmaxDeposit] = useState(0);
  const [noticMessage, setNoticMessage] = useState("");
  const [maintenanceMessage, setMaintenanceMessage] = useState("");


  const [version, setVersion] = useState("");

  const [settingId, setSettingId] = useState("");

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  useEffect(() => {
    const data = axios.get(baseUrl + "settings/data", {}).then((res) => {
      console.log(res.data);
      setSettingId((res.data._id) ? res.data._id : '');
      setWebTitle(res.data.WebTitle)
      setCheckedRoom(res.data.roomcodeAuto);
      setWebName(res.data.WebsiteName);
      setMsg(res.data.msg);
      setCompanyName(res.data.CompanyName);
      setCompanyAddress(res.data.CompanyAddress);
      setCompanyMobile(res.data.CompanyMobile);
      setCompanyEmail(res.data.CompanyEmail);
      setCompanyWebsite(res.data.CompanyWebsite);
      setRoomCode(res.data.roomcodeUrl);
      setRoomCodeUrl(res.data.roomcodeUrlPopular);
      setIsBonus(res.data.isBonus);
      setBonusSignup(res.data.bonusSignup);
      setLogo(res.data.Logo);
      setSmallLogo(res.data.SmallLogo);
      setLandingImage1(res.data.LandingImage1);
      setLandingImage2(res.data.LandingImage2);
      setLandingImage3(res.data.LandingImage3);
      setLandingImage4(res.data.LandingImage4);
      issetLandingImage1(res.data.isLandingImage1);
      issetLandingImage2(res.data.isLandingImage2);
      issetLandingImage3(res.data.isLandingImage3);
      issetLandingImage4(res.data.isLandingImage4);
      setIsWebsiteOn(res.data.isWebsiteOn);
      setIsUpiOn(res.data.isUpiOn);
      setIsUpiLiveOn(res.data.isUpiLiveOn);
      setIsAccountOn(res.data.isAccountOn);
      setIsBatOn(res.data.isBatOn);
      setrefer(res.data.refer);
      setcommission(res.data.commission);
      setminWithDrawal(res.data.minWithDrawal);
      setmaxWithDrawal(res.data.maxWithDrawal);
      setminDeposit(res.data.minDeposit);
      setmaxDeposit(res.data.maxDeposit);
      setNoticMessage(res.data.noticMessage);
      setVersion(res.data.version);
      setMaintenanceMessage(res.data.maintenanceMessage);

    });
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("settingId", settingId);
    formData.append("WebTitle", WebTitle);
    formData.append("WebsiteName", WebsiteName);
    formData.append("msg", msg);
    formData.append("CompanyName", CompanyName);
    formData.append("CompanyAddress", CompanyAddress);
    formData.append("CompanyMobile", CompanyMobile);
    formData.append("CompanyEmail", CompanyEmail);
    formData.append("CompanyWebsite", CompanyWebsite);
    formData.append("roomcodeUrl", RoomCode);
    formData.append("roomcodeUrlPopular", RoomCodeUrl);
    formData.append("roomcodeAuto", checkedRoom);
    formData.append("isBonus", isBonus);
    formData.append("bonusSignup", bonusSignup);
    formData.append("commission", commission);
    formData.append("refer", refer);
    formData.append("Logo", Logo);
    formData.append("SmallLogo", SmallLogo);
    formData.append("LandingImage1", LandingImage1);
    formData.append("LandingImage2", LandingImage2);
    formData.append("LandingImage3", LandingImage3);
    formData.append("LandingImage4", LandingImage4);
    formData.append("isLandingImage1", isLandingImage1);
    formData.append("isLandingImage2", isLandingImage2);
    formData.append("isLandingImage3", isLandingImage3);
    formData.append("isLandingImage4", isLandingImage4);
    formData.append("isWebsiteOn", isWebsiteOn);
    formData.append("isUpiOn", isUpiOn);
    formData.append("isUpiLiveOn", isUpiLiveOn);
    formData.append("isAccountOn", isAccountOn);
    formData.append("isBatOn", isBatOn);
    formData.append("minWithDrawal", minWithDrawal);
    formData.append("maxWithDrawal", maxWithDrawal);
    formData.append("minDeposit", minDeposit);
    formData.append("maxDeposit", maxDeposit);
    formData.append("noticMessage", noticMessage);
    formData.append("maintenanceMessage", maintenanceMessage);
    formData.append("version", version);
    const response = await axios.post(
      baseUrl + `settings`,
      formData
    );
    console.log(response.data.status);
    if (response.data.status === 'success') {
      alert("Settings submitted successfully");
    } else {
      alert("Settings Not Submitted");
    }
  };




  useEffect(() => {
    const Logo1 = document.getElementById("Logo");
    const Logo2 = document.getElementById("SmallLogo");
    const LandingImage1 = document.getElementById("LandingImage1");
    const LandingImage2 = document.getElementById("LandingImage2");
    const LandingImage3 = document.getElementById("LandingImage3");
    const LandingImage4 = document.getElementById("LandingImage4");

    Logo1.onchange = (e) => {
      const [file] = Logo1.files;
      setLogo(file);
    };
    Logo2.onchange = (e) => {
      const [file] = Logo2.files;
      setSmallLogo(file);
    };
    LandingImage1.onchange = (e) => {
      const [file] = LandingImage1.files;
      setLandingImage1(file);
    };
    LandingImage2.onchange = (e) => {
      const [file] = LandingImage2.files;
      setLandingImage2(file);
    };
    LandingImage3.onchange = (e) => {
      const [file] = LandingImage3.files;
      setLandingImage3(file);
    };
    LandingImage4.onchange = (e) => {
      const [file] = LandingImage4.files;
      setLandingImage4(file);
    };
  }, []);

  const handleChangeRoom = (val) => {
    setCheckedRoom(val);
  };
  const handleIsBonus = (val) => {
    setIsBonus(val);
  };

  // const handleIsRefer = (val) => {
  //   setrefer(val);
  // };

  // const handleIsCommission = (val) => {
  //   setcommission(val);
  // };



  const handleIsWebsiteOn = (val) => setIsWebsiteOn(val);

  const handleIsUpiOn = (val) => setIsUpiOn(val);
  const handleIsUpiLiveOn = (val) => setIsUpiLiveOn(val);
  const handleIsAccountOn = (val) => setIsAccountOn(val);
  const handleIsBatsOn = (val) => setIsBatOn(val);

  return (
    <>
      <div className="card">
        <div className="card-body">
          <h3 className="text-uppercase font-weight-bold my-3">Website Settings</h3>
          <h4 className="text-uppercase font-weight-bold my-3" >UI Settings</h4>
          <form
            onSubmit={handleSubmit}
            method="patch"
            encType="multipart/form-data"
          >
            <div className="form-row">
              <div className="form-group col-md-4">

                <label htmlFor="WebsiteName">Website Title</label>
                <input
                  className="form-control"
                  type="text"
                  value={WebTitle}
                  onChange={(e) => setWebTitle(e.target.value)}
                />
              </div>
              <div className="form-group col-md-4">

                <label htmlFor="WebsiteName">Website Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={WebsiteName}
                  onChange={(e) => setWebName(e.target.value)}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="WebsiteName">Commpany Name</label>
                <input
                  className="form-control"
                  type="text"
                  value={CompanyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
            </div>


            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="WebsiteName">Commpany Address</label>
                <input
                  className="form-control"
                  type="text"
                  value={CompanyAddress}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="WebsiteName">Commpany Mobile</label>
                <input
                  className="form-control"
                  type="text"
                  value={CompanyMobile}
                  onChange={(e) => setCompanyMobile(e.target.value)}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="WebsiteEmail">Commpany Email</label>
                <input
                  className="form-control"
                  type="text"
                  value={CompanyEmail}
                  onChange={(e) => setCompanyEmail(e.target.value)}
                />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="WebsiteWebsite">Commpany Website</label>
                <input
                  className="form-control"
                  type="text"
                  value={CompanyWebsite}
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                  placeholder="Enter Company Website"
                />
              </div>

            </div>

            <div className="form-row">

              <div className="form-group col-md-4">

                <label htmlFor="WebsiteName">Right Logo</label>
                <input className="form-control" type="file" name="Logo" id="Logo" />
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="WebsiteName">Left Logo</label>
                <input className="form-control" type="file" name="SmallLogo" id="SmallLogo" />

              </div>
              <div className="form-group col-md-4">

                <label htmlFor="WebsiteName">Message Outer</label>
                <input
                  className="form-control"
                  type="text"
                  value={msg}
                  onChange={(e) => setMsg(e.target.value)}
                  placeholder="Msg"
                />
              </div>

              <div className="form-group col-md-4">

                <label htmlFor="WebsiteName">Message Inner</label>
                <input
                  className="form-control"
                  type="text"
                  value={noticMessage}
                  onChange={(e) => setNoticMessage(e.target.value)}
                  placeholder="Msg"
                />
              </div>

              
              <div className="form-group col-md-4">

                <label htmlFor="WebsiteName">Website Maintenance Message</label>
                <input
                  className="form-control"
                  type="text"
                  value={maintenanceMessage}
                  onChange={(e) => setMaintenanceMessage(e.target.value)}
                  placeholder="Enter Website Maintenance Message"
                />
              </div>

            </div>

            <div className="form-row">
              <div className="form-group col-md-4">

                <label htmlFor="WebsiteName">Game image (1) </label>
                <input className="form-control" type="file" name="LandingImage1" id="LandingImage1" />
                <select className="form-control mt-3" name="" id="" value={isLandingImage1} onChange={(e) => issetLandingImage1(e.target.value)}>
                  <option value="true">on</option>
                  <option value="false">off</option>
                </select>
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="WebsiteName">Game image (2)</label>
                <input className="form-control" type="file" name="LandingImage2" id="LandingImage2" />
                <select className="form-control mt-3" name="" id="" value={isLandingImage2} onChange={(e) => issetLandingImage2(e.target.value)}>
                  <option value="true">on</option>
                  <option value="false">off</option>
                </select>
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="WebsiteName">Game image (3)</label>
                <input className="form-control" type="file" name="LandingImage3" id="LandingImage3" />
                <select className="form-control mt-3" name="" id="" value={isLandingImage3} onChange={(e) => issetLandingImage3(e.target.value)}>
                  <option value="true">on</option>
                  <option value="false">off</option>
                </select>
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="WebsiteName">Game image (2)</label>
                <input className="form-control" type="file" name="LandingImage4" id="LandingImage4" />
                <select className="form-control mt-3" name="" id="" value={isLandingImage4} onChange={(e) => issetLandingImage4(e.target.value)}>
                  <option value="true">on</option>
                  <option value="false">off</option>
                </select>
              </div>

            </div>


            <div className="form-row">
              <div className="form-group col-md-4">

                <label htmlFor="WebsiteName">version</label>

                <input
                  className="form-control"
                  type="text"
                  value={version}
                  onChange={(e) => setVersion(e.target.value)}
                />
              </div>


            </div>
            <div className="form-row">
              <div className="form-group col-md-2" >
                <label htmlFor="autoroomcode" style={{ display: "block" }}>Auto roomcode</label>
                <ReactSwitch
                  checked={checkedRoom}
                  onChange={handleChangeRoom}
                  style={{ display: "none" }}
                />
              </div>
              <div className="form-group col-md-5">
                <label htmlFor="WebsiteWebsite">Classic Room Code Url</label>
                <input
                  className="form-control"
                  type="url"
                  value={RoomCode}
                  onChange={(e) => setRoomCode(e.target.value)}
                  placeholder="Enter Url"
                />
              </div>
              <div className="form-group col-md-5">
                <label htmlFor="WebsiteWebsite"> Popular Room Code Url </label>
                <input
                  className="form-control"
                  type="url"
                  value={RoomCodeUrl}
                  onChange={(e) => setRoomCodeUrl(e.target.value)}
                  placeholder="Enter Url"
                />
              </div>
            </div>




            <div className="form-row">
              <div className="form-group col-md-2" >
                <label htmlFor="autoroomcode" style={{ display: "block" }}>Signup Bonus</label>
                <ReactSwitch
                  checked={isBonus}
                  onChange={handleIsBonus}
                  style={{ display: "none" }}
                />
              </div>
              <div className="form-group col-md-5">
                <label htmlFor="WebsiteWebsite">Signup Bonus Amount</label>
                <input
                  className="form-control"
                  type="number"
                  value={bonusSignup}
                  onChange={(e) => setBonusSignup(e.target.value)}
                  placeholder="Enter Amount"
                />
              </div>
            </div>


            <div className="form-row">
              {/* <div className="form-group col-md-2" >
                  <label htmlFor="autoroomcode" style={{display:"block"}}>Refer</label>
                  <ReactSwitch
                              checked={refer}
                              onChange={handleIsRefer}
                              style={{ display: "none" }}
                            />
              </div> */}
              <div className="form-group col-md-5">
                <label htmlFor="WebsiteWebsite">Refer</label>
                <input
                  className="form-control"
                  type="number"
                  value={refer}
                  onChange={(e) => setrefer(e.target.value)}
                  placeholder="Enter Amount"
                />
              </div>

              <div className="form-group col-md-5">
                <label htmlFor="WebsiteWebsite">commission</label>
                <input
                  className="form-control"
                  type="number"
                  value={commission}
                  onChange={(e) => setcommission(e.target.value)}
                  placeholder="Enter Amount"
                />
              </div>
            </div>



            <div className="form-row">
              {/* <div className="form-group col-md-2" >
                  <label htmlFor="autoroomcode" style={{display:"block"}}>commission</label>
                  <ReactSwitch
                              checked={commission}
                              onChange={handleIsCommission}
                              style={{ display: "none" }}
                            />
              </div> */}


              <div className="form-group col-md-5">
                <label htmlFor="WebsiteWebsite">MINIMUM WithDrawal</label>
                <input
                  className="form-control"
                  type="number"
                  value={minWithDrawal}
                  onChange={(e) => setminWithDrawal(e.target.value)}
                  placeholder="Enter Amount"
                />
              </div>


              <div className="form-group col-md-5">
                <label htmlFor="WebsiteWebsite">MAXIMUM WithDrawal</label>
                <input
                  className="form-control"
                  type="number"
                  value={maxWithDrawal}
                  onChange={(e) => setmaxWithDrawal(e.target.value)}
                  placeholder="Enter Amount"
                />
              </div>

            </div>



            <div className="form-row">
              <div className="form-group col-md-5">
                <label htmlFor="WebsiteWebsite">MINIMUM Deposit</label>
                <input
                  className="form-control"
                  type="number"
                  value={minDeposit}
                  onChange={(e) => setminDeposit(e.target.value)}
                  placeholder="Enter Amount"
                />
              </div>


              <div className="form-group col-md-5">
                <label htmlFor="WebsiteWebsite">MAXIMUM Deposite</label>
                <input
                  className="form-control"
                  type="number"
                  value={maxDeposit}
                  onChange={(e) => setmaxDeposit(e.target.value)}
                  placeholder="Enter Amount"
                />
              </div>

            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="isWebsiteOn" style={{ display: "block" }}>Website On/Off</label>
                <ReactSwitch
                  checked={isWebsiteOn}
                  onChange={handleIsWebsiteOn}
                  style={{ display: "none" }}
                />
              </div>
            </div>



            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="isUpiOn" style={{ display: "block" }}>UPI On/Off</label>
                <ReactSwitch
                  checked={isUpiOn}
                  onChange={handleIsUpiOn}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="isUpiLiveOn" style={{ display: "block" }}>UPIPayment Gateway(2000) On/Off</label>
                <ReactSwitch
                  checked={isUpiLiveOn}
                  onChange={handleIsUpiLiveOn}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="isUpiOn" style={{ display: "block" }}>Account On/Off</label>
                <ReactSwitch
                  checked={isAccountOn}
                  onChange={handleIsAccountOn}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="isUpiOn" style={{ display: "block" }}>BATS On/Off</label>
                <ReactSwitch
                  checked={isBatOn}
                  onChange={handleIsBatsOn}
                  style={{ display: "none" }}
                />
              </div>
            </div>


            <div className="form-row">

              <div className="form-group col-md-4">
                <button type="submit" className="btn btn-dark">submit</button>
              </div>
            </div>

          </form>
          <Gateway />
        </div>
      </div>
    </>
  );
};
